
import Page from "@/core-ui/Page.vue";
import ButtonSelect from "@/core-ui/forms/components/ButtonSelect.vue";
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import workloadService from "@/cluster-ui/services/workload.service";
import {
    ActionOn,
    DataGridAction,
    toDisplayColumns,
    useDataGridModel,
    useDeleteModalAction,
} from "@/core-ui/data-grid/compositions";
import { createModelProps } from "@/models/deployments.model";
import { computed, defineComponent } from "vue";
import { ClusterUUIDKey, useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import DeploymentDetailsPanel from "@/components/deployments/DeploymentDetailsPanel.vue";
import { useRouter } from "vue-router";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import { storageService } from "@/core-ui/data-grid/services";
import authStore from "@/stores/authStore";
import { Deployment } from "@/types/deployments";
import DeleteModalAction from "@/core-ui/data-grid/components/DeleteModal/DeleteModalAction.vue";
import { meta, ModalType as Item } from "@/models/deployments.model";

export default defineComponent({
    components: { DeploymentDetailsPanel, DataGrid, Page, ButtonSelect, DeleteModalAction },
    props: {
        clusterUuid: String,
    },
    setup() {
        const clusterKey: ClusterUUIDKey = useClusterUUIDKey({
            key: "deployment",
        });
        const router = useRouter();
        const selectedMode = computed<string>({
            get: () => {
                const v = storageService.getStr("deployments_view", "Current");
                if (v == "History") {
                    router.push("/deployments/history");
                }
                return v;
            },
            set: (v) => storageService.setStr("deployments_view", v),
        });
        const model = useDataGridModel(
            createModelProps(clusterKey, {
                get mode() {
                    return selectedMode.value;
                },
            }),
        );

        const actionDisabledMsgs = {
            connectivityProblem: (request: string, problem: string | undefined) =>
                problem === undefined || "undefined" ? `${request} is blocked.` : `${request} is blocked. (${problem})`,
        };
        const deleteModal = useDeleteModalAction<Item>({
            modelMeta: meta,
            delete: (deploy: Deployment[]) => {
                return workloadService.deleteInferenceWorkload(deploy[0]).then((d) => d) as any;
            },
        });

        return {
            model,
            deleteModal,
            selectedMode,
            get displayColumns() {
                return toDisplayColumns(null, model.columns);
            },
            actions: [
                {
                    on: ActionOn.None,
                    key: "add",
                    icon: "raicon-add",
                    label: `New Deployment`,
                    aid: `new-deployment`,
                    permitted: authStore.isMLEngineer,
                    disabled: () =>
                        "true".localeCompare(clusterApi.inferenceAvailabilityStatus) != 0 &&
                        actionDisabledMsgs.connectivityProblem(
                            "Creating an inference workload",
                            clusterApi.inferenceAvailabilityStatus,
                        ),
                    func: () => router.push("/deployments/new") as any,
                },
                {
                    on: ActionOn.Item,
                    key: "delete",
                    icon: "raicon-remove",
                    label: `Delete Deployment`,
                    aid: `delete-deployment`,
                    permitted: authStore.isMLEngineer,
                    disabled: (item) => {
                        const isDisabled = !(item as Deployment).inferenceworkloadName || !clusterApi.connected;
                        const disabledRes = !(item as Deployment).inferenceworkloadName
                            ? "not an inference workload"
                            : "missing connectivity to cluster";
                        return isDisabled && actionDisabledMsgs.connectivityProblem("Delete deployment", disabledRes);
                    },
                    func: deleteModal.handle,
                },
            ] as DataGridAction[],
        };
    },
});
